.tl-outer {
    display: flex;
    justify-content: center;
    border: 2px solid rgb(224, 224, 224);
    border-radius: 20px;
    border-bottom: #DCE6FF;
    margin: 80px 60px;
}
.tl-section {
    width: 93%;
    padding: 32px;
    gap: 24px;
    background: white;
}
.tl-content {
    display: flex;
}
.tlinfo-card-content {
    display: flex;
    margin-top: 4em;
}
/* Text */
.tl-text-content {
    width: 100%;
}
.tl-text-title {
    margin: 16px 0;
    color: #6F7074;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;
    /* letter-spacing: .7px; */
}
.tl-text-num {
    color: #8348f0;
    font-size: 16px;
    background-color: rgb(246, 246, 252);
    padding: 6px 15px;
    border-radius: 24px;
}
.tl-text-desc {
    /* width: 55%; */
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 2vw;
    font-weight: bolder;
    /* letter-spacing: .7px; */
    /* line-height: 2.6rem; */
}

/* Cards */
.tl-card-outer {
    display: flex;
    align-items: center;
    gap: 9px;
    margin-top: 20px;
    width: 100%;
    padding: 0px 9px;
}
.tl-card-img {
    height: 4em;
}
.tl-cards-content {
    margin: 50px 0;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
}
.tl-cards {
    height: auto;
    border: 2px solid transparent;
    border-radius: 12px;
    background-color: #F9F5FF;
    position: relative;
    width: 100%;
}
.tl-card-title {
    /* margin: 32px 0 0px 0; */
    width: 100%;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 1vw;
    font-weight: bold;
    /* letter-spacing: .8px; */
    text-align: center;
}
.tl-card-description {
    margin: 24px;
    color: rgb(77, 75, 75);
    font-family: 'Poppins', sans-serif;
    font-size: 0.8vw;
    /* letter-spacing: .8px; */
    /* line-height: 1.5rem; */
}
.tl-card-icon {
    position: absolute;
    top: -24px;
    left: 44%;
    padding: 10px;
    background-color: #DCE6FF;
    border-radius: 30px;
}
.tl-fa-icon {
    color: #2C96FF;
    font-size: 24px;
}
/* Responsive */


/* statscard */

.tlinfo-card {
    width: 180px;
    height: 100px;
    gap: 22px;
    text-align: right;
    font-family: 'Poppins', sans-serif;
}
.tlinfo-card-img {
    width: 100%;
    height: 275px;
    padding: 16px 32px 0;
    background-position: center;
    background-color: #DDE5F8;
    border-radius: 20px;
}
.tlinfo-card-name {
    margin: 12px 0 0 0;
    font-size: 22px;
    font-weight: bold;
    /* letter-spacing: .7px; */
}
.tlinfo-card-title {
    margin: 6px 0 8px;
    color: #494949;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    /* letter-spacing: .7px; */
}
.tlinfo-card-stars {
    font-size: 0.6vw;
    font-weight: bold;
    /* letter-spacing: .7px; */
    width: 100%;
}
.tlinfo-card-reviews {
    color: #5a5a5a;
    font-size: 18px;
    font-weight: bold;
    /* letter-spacing: .7px; */
}
/* Responsive */
@media screen and (max-width: 1030px) {
    .tl-outer{
        margin: 7vw;
    }
    .tlinfo-card {
        text-align: left;
    }
    .tlinfo-description {
        margin: 48px 0 0;
        font-size: 18px;
    }
    .tl-section {
        /* letter-spacing: .8px; */
        padding: 15px;
    }
    .tl-text-title {
        font-size: 22px;
    }
    .tl-text-desc {
        font-size: 3.5vw;
        /* line-height: 1rem; */
        width: auto;
    }
    .tl-content {
        display: block;
    }
    .tl-text-num{
        font-size: 2vw;
        padding: 3px 7px;
    }
    .tlinfo-card-name{
        font-size: 3vw;
    }
    .tlinfo-card-desc1{
        font-size: 3vw;
    }
    .tlinfo-card-desc2{
        font-size: 3vw;
    }
    .tlinfo-card-content{
        margin-top: 3vw;
    }
    .tlinfo-card{
        height: fit-content;
    }
}


@media screen and (max-width: 1030px) {
    .tl-cards-content {
        grid-template-columns: repeat(2, 1fr);
        gap: 8vw;
    }
}
@media screen and (max-width: 1030px) {

    .tl-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }
    .tl-card-title {
        font-size: 2.6vw;
    }
    .tlinfo-card-stars{
        font-size: 1.4vw;
    }
    .tl-card-img{
        height: 3em;
    }
    .tl-card-description{
        font-size: 2.4vw;
        /* line-height: 0.8rem; */
    }
}


