.navbar-section {
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 3;
}
.navbar-title a {
    color: #8348f0;
    /* letter-spacing: .6px; */
    text-decoration: none;
}
.navbar-title {
    font-family: 'Poppins', sans-serif;
}
.navbar-sign {
    height: 40px;

}
.navbar-sign:focus{
    outline: none;
}
.navbar-items {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    font-family: 'Rubik', sans-serif;
    border: 1px solid rgb(235, 226, 226);
    border-radius: 27px;
    padding: 10px;
    padding-left: 3em;
    padding-right: 3em;
}
.navbar-items > li {
    list-style-type: none;
}
.navbar-items li a{
    text-decoration: none;
    color: black;
    font-size: 16px;
    letter-spacing: .8px;
}
.navbar-items li a:hover {
    color: #8348f0;
}

.navbar-items li a.active {
    color: #8348f0;
    text-decoration: underline; 
    text-decoration-thickness: 1.5px; 
    text-underline-offset: 9px;
}

.navbar-btn {
    padding: 10px 14px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background: radial-gradient(#AA7CF3, #813FEA);
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}

/* Hamburger Icon */
.mobile-nav {
    display: none;
}
.hamb-icon {
    width: 26px;
    height: 21px;
    cursor: pointer;
}
.hamb-icon:hover {
    color: #8348f0;
    background-color: #c2ade8;
}
/* Mobile Navbar */
.mobile-navbar {
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 0;
    left: -100%;
    background-color: white;
    z-index: 20;
    align-items: center;
    transition: left .5s ease-in-out;
}
.mobile-navbar-close {
    position: absolute;
    top: 28px;
    right: 28px;
}
.mobile-navbar-close .hamb-icon:hover {
    color: #8348f0;
}
.open-nav {
    left: 0;
}
.mobile-navbar-links {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    gap: 24px;
    text-align: center;
}
.mobile-navbar-links li a {
    text-decoration: none;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: .8px;
    transition: color .3s ease-in-out;
}
.mobile-navbar-links li a:hover {
    color: #8348f0;
}
.mobile-navbar-links li a.active {
    color: #8348f0;
    text-decoration: underline; 
    text-decoration-thickness: 1.5px; 
    text-underline-offset: 9px;
}
/* Responsive */
@media screen and (max-width: 1030px) {
    .navbar-btn, .navbar-items {
        display: none;
    }
    .mobile-nav {
        display: block;
    }
    .navbar-sign {
        height: 7vw;
    }
    .mobile-navbar-links li {
        font-size: 4vw;
    }
}

@media screen and (max-width: 550px) {
    .navbar-sign{
        height: 9vw;
    }
}
