.projectinfo-outer {
    display: flex;
    justify-content: center;
    /* border: 2px solid #8348f0; */
    /* border-radius: 24px; */
    margin: 60px;
    background-size: cover;
    position: relative;
    border: 2px solid transparent;
    border-radius: 20px;
    background: 
    linear-gradient(to top, #fffcfc, #fffcfc), 
    linear-gradient(to top, #F1E8FF , #FE45B1, #772EE5); 
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    
  
}

.projectinfo-outer::before {
    content: '';
    position: absolute;
    width: 20vw;
    height: 28vw;
    background: radial-gradient(circle, rgb(243 189 221 / 80%), rgba(255, 0, 150, 0));
    border-radius: 50%;
    top: 44%;
    left: 9%;
    transform: translate(-50%, -50%);
    z-index: -1;
    box-shadow: 0px 17px 0px #ff2df715;
    filter: blur(29px);
  }
  .projectinfo-outer::after {
    content: '';
    position: absolute;
    width: 20vw;
    height: 28vw;
    background: radial-gradient(circle, rgb(243 189 221 / 80%), rgba(255, 0, 150, 0));
    border-radius: 50%;
    top: 44%;
    left: 90%;
    transform: translate(-50%, -50%);
    z-index: -1;
    box-shadow: 0px 17px 0px #ff2df715;
    filter: blur(29px);
  }
  .ellipse{
    content: '';
    position: absolute;
    width: 99vw;
    height: 15.3vw;
    background: radial-gradient(circle, rgb(243 189 221 / 80%), rgba(255, 0, 150, 0));
    border-radius: 50%;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    box-shadow: 0px 17px 0px #9245FE11;
    filter: blur(29px);
  } 
.projectinfo-section {
    /* height: 33em; */
    width: 81%;
    padding: 42px;
    padding-bottom: 5px;
    margin: 32px;
    /* margin-bottom: 0px; */
    text-align: center;
    pointer-events: none;
    background-color: #fffcfc00;
    border-radius: 24px;

}
.projectinfo-title-content {
    margin: 0 0 1.4rem 0;
}
.projectinfo-title {
    font-family: 'Poppins', sans-serif;
    font-size: 2vw;
    font-weight: bold;
    /* letter-spacing: .7px; */
    /* padding: 0px 172px; */

}

.hlt {
    background-image: linear-gradient(135deg,#934EF4, #FFA63E);
    color: transparent;
    background-clip: text;
    background-color: white;
}

.projectinfo-description {
    margin: 45px auto 0;
    text-align: center;
    color: #242323;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1vw;
    /* letter-spacing: .8px; */
    /* line-height: 1.8rem; */
}
/* Stats Cards */
.stats-cards-content {
    width: 100%;
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border: 2px solid rgb(231, 231, 231);
    border-radius: 10px;
    padding: 5px 55px;
}

.vertical {
    border-right: 2px solid #cfc9c9;
    height: 30px;
}
.stats-card {
    width: 250px;
    height: 100px;
    gap: 22px;
    text-align: left;
    font-family: 'Rubik', sans-serif;
}
.stats-card-name {
    margin: 12px 0 0 0;
    font-size: 1.3vw;
    font-weight: bold;
    /* letter-spacing: .7px; */
}


/* Responsive */
@media screen and (max-width: 1030px) {
    .projectinfo-outer {
        margin: 7vw;
    }
    .projectinfo-section {
        padding: 0;
        margin: 1.5rem 0;
        height: fit-content;
        width: fit-content;
    }
    .projectinfo-title {
        font-size: 2.3vw;
        padding: 0;
    }
    .projectinfo-description {
        margin: 0.7rem 0 0;
        font-size: 1.4vw;
        /* line-height: 0.8rem; */
    }
    .stats-card {
        width: auto;
        height: auto;
        text-align: center;
    }
    .stats-card-name {
        font-size: 2vw;
        /* letter-spacing: 0; */
    }
    .stats-cards-content{
        margin: auto;
        padding: 0 4px;
        /* width: 50%; */
        gap: 17px;
        /* display: block; */
    }
    .vertical {
        border-bottom: 2px solid gray;
        /* width: 20px; */
        /* border-right: 0; */
        margin: auto;
        height: 1em;
    }
}
