.upper-outer {
    height: 35em;
}
.upper-section-container {
    width: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    height: 31em;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 17em 0px;
    padding-bottom: 25em;
}
.upper-hero-section {
    width: 100%;
    /* padding: 32px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 30px;
    
}
#aboutbg-video{
    filter: brightness(0.5) hue-rotate(-315deg) saturate(2);
    opacity: 0.88;
    width: 90%;
    height: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: -1;
    object-fit: cover;
    border-radius: 24px;
    pointer-events: none;
}

/* Text section */
 .upper-text-section {
    padding: 0 32px;
    text-align: center;
    margin-top: 24em;
}
.upper-text-headline { 
    /* margin-bottom: -34px; */
    margin-top: -27px;
    margin-left: 5px;
    color: rgb(77, 75, 75);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
    /* letter-spacing: .7px; */
    background-color: white;
    border-radius: 42px;
    padding: 22px 25px;
    position: absolute;
    justify-content: center;
    height: 3.5em;
}

.upper-text-title {
    /* width: 500px; */
    color: rgb(255, 255, 255);
    font-family: 'Poppins';
    font-size: 2.7vw;
    font-weight: bolder;
}
.upper-text-descritpion {
    width: 475px;
    margin: 32px 0;
    color: rgb(255, 255, 255);
    font-family: 'Poppins';
    font-size: 18px;
    /* letter-spacing: .7px; */
    /* line-height: 1.6rem; */
    font-weight: bolder;
}

/* Responsive */

@media screen and (max-width: 1030px) {
    .upper-outer{
        height: 26em;
    }
    .upper-section-container{
        height: 21em;
        margin: 14em 0;
    }

    .upper-text-title {
        font-size: 3vw;
    }
    
    
    .scroll-up {
        bottom: 32px;
        right: 32px;
    }
} 
@media screen and (max-width: 550px){
    #aboutbg-video{
        height: 63%;
    }
    .upper-text-section{
        margin-top: 16em;
    }
    .upper-outer{
        height: 17em;
    }

}   