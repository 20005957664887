

.aboutform-section {
    /* width: 90%; */
    padding: 32px;
    padding-top: 20px;
    background-color: white;
    margin: 52px 70px;

}


.aboutform-title-content h1 {
    color: black;
    font-size: 1.6vw;
    font-family: 'Poppins', 'sans-serif';
    padding: 3px;
    margin-bottom: 15px;
    font-weight: normal;
    text-align: center;
}




.aboutform-outer-section {
    margin: 30px 0px;
    text-align: left;
}

.aboutform-head-section {
    font-size: 2.8vw;
    font-family: 'Poppins', 'sans-serif';
    margin-bottom: 20px;
    text-align: center;
    font-weight: bolder;
    color: #813FEA;
}
.aboutform-content{
    display: grid;
}

.aboutform-content label{
    font-size: 1.2vw;
    margin: 12px 5px 0;
}

.aboutform-content textarea{
    height: 7em;
}
.aboutform-input {
    font-size: 1.2vw;
    border: 2px solid transparent;
    /* width: 23vw; */
    border: 1px solid #d2d2d2;
    margin: 1rem 2rem;
    margin-left: 0px;
    padding: 20px 20px;
    border-radius: 10px;

}
.aboutform-input:focus{
    outline: none;
}
.aboutform-btn-container{
    text-align: center;
}
.aboutform-btn{
    padding: 10px 20px;
    color: white;
    border: 4px solid white;
    border-radius: 28px;
    outline: transparent;
    background: radial-gradient(#AA7CF3, #813FEA);
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    /* letter-spacing: .8px; */
    cursor: pointer;
    transition: all .4s ease;
    border-radius: 14px;
    margin-top: 1vw;
    text-align: center;
}
/* .aboutform-content {
} */
/* Responsive */
@media screen and (max-width: 1030px) {
    .aboutform-title-content h1 {
        font-size: 3vw;
    }
    .aboutform-section {
        margin: 0;
        padding: 3vw;
        text-align: center;
    }
    .aboutform-head-section{
        font-size: 4vw;
        text-align: center;
    }
    .aboutform-input{
        /* margin: 2vw; */
        padding: 3vw;
        font-size: 2.8vw;
        /* width: 27vw; */
    }
    .aboutform-btn{
        font-size: 3vw;
        padding: 1vw 1.5vw;
    }
    .aboutform-btn-container{
        text-align: center;
    }
    .aboutform-content label{
        font-size: 2.5vw;
    }
}
