.about1-outer {
    display: flex;
    justify-content: center;
}
.about1-section {
    width: 80%;
    /* padding: 32px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background: white;
}
#Ai-video {
    pointer-events: none;
    height: 27vw;
    filter: brightness(1) hue-rotate(-902deg) saturate(1);
}
/* Image */
.about1-image-content {
    width: 50%;
    /* max-width: 100%; */
    text-align: center;
}
.about1-image1 {
    width: 80%;
    height: auto;
}
/* Text */
.about1-text-content {
    /* width: 50%; */
    margin-top: 6em;
}
.about1-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.8vw;
    font-weight: bold;
}

.about1-description {
    margin: 8px 0px;
    color: #9d9797;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    /* letter-spacing: 0.8px; */
    /* line-height: 30px; */
}

/* 
.text-appointment-btn {
    padding: 10px 20px;
    color: white;
    border: 4px solid white;
    border-radius: 28px;
    outline: transparent;
    background: radial-gradient(#baa0e2, #813FEA);
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
    border-radius: 30px;
} */
/* .fa-icon {
    color: #9D2553;
} */
/* Responsive */
@media screen and (max-width: 1030px) {
    .about1-section {
        display: block;
    }
    .about1-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }
    .about1-image1 {
        width: 100%;
        height: 100%;
    }
    .about1-text-content {
        width: 100%;
        margin: 0;
    }
    #Ai-video{
        height: 55vw;
    }
    .about1-title {
        font-size: 5vw;
    }
    .about1-description {
        font-size: 2.3vw;
        margin: 12px 0;
        /* line-height: 0.8rem; */
    }
}
