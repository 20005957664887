
.ops-section {
    /* width: 80%; */
    /* padding: 32px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 165px 34px;
    margin-top: 140px;
}

 .sides-img {
    border: 2px solid white;
    border-radius: 12px;
    height: 23em;
    object-fit: cover;
    /* background-image: linear-gradient(80deg, #cbe9f5, #ebe0e0ab); */
 }
 
.ops-head {
    width: fit-content;
    border: 2px solid #8348f0;
    margin: 8px 0px;
    margin-bottom: 25px;
    color: #8348f0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1vw;
    /* letter-spacing: .7px; */
    /* line-height: 1.7rem; */
    padding: 1px 17px;
    border-radius: 24px;
    font-weight: bold;
}
/* Image */
.ops-image-content {
    /* width: 50%; */
    /* padding-left: 50px; */
    text-align: center;
}

/* Text */
.ops-text-content {
    width: 50%;
    margin-left: 35px;
}
.ops-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.8vw;
    font-weight: bold;
    width: 14em;
    margin: 26px 0;
}

.ops-description {
    margin: 8px 0px;
    margin-bottom: 18px;
    color: #9d9797;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    /* letter-spacing: .83px; */
    /* line-height: 1.7rem; */
}

/* Steps */

/* .text-appointment-btn {
    padding: 10px 20px;
    color: white;
    border: 4px solid white;
    border-radius: 28px;
    outline: transparent;
    background: radial-gradient(#baa0e2, #813FEA);
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    transition: all .4s ease;
    border-radius: 30px;
} */

/* Responsive */

@media screen and (max-width: 1030px) {
    /* .ops-outer{
        display: flex;
    } */
    .ops-section {
        /* display: block; */
        margin: 7vw;
    }
    #blueprint{
        display: flex;
        flex-wrap: wrap-reverse;
    }
    #copilot{
        display: flex;
        flex-wrap: wrap;
    }
    #healthcare{
        display: flex;
        flex-wrap: wrap;
    }
    #strategy{
        display: flex;
        flex-wrap: wrap-reverse;
    }
    #zero{
        display: flex;
        flex-wrap: wrap-reverse;
    }
    #zone{
        display: flex;
        flex-wrap: wrap;
    }
    .ops-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }
    .ops-text-content {
        width: 100%;
        margin: 10px 5px;
    }
    .sides-img {
        height: 46vw;
    }
    .ops-head {
        font-size: 2.5vw;
    }
    .ops-description{
        font-size: 2.6vw;
        color: rgb(77, 75, 75);
    }
    .ops-title {
        font-size: 5vw;
    }
}
