.contact-outer {
    display: flex;
    justify-content: center;
    background-color: #f1f1f1;
    margin: 50px 60px;
    border-radius: 20px;
    
}
.contact-section {
    margin: 36px;
    padding: 32px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94%;
    /* height: 20em; */
    background: url(../Assets/contactbg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    filter: brightness(1) hue-rotate(-700deg) saturate(1);

}
/* Image */

.contact-image1 {
    width: 80%;
    height: auto;

}
/* Text */
.contact-text-content {
    width: 50%;
    text-align: right;
}
.contact-title {
    font-family: 'Poppins', sans-serif;
    font-size: 2vw;
    font-weight: bold;
    color: white;
}


.contact-description {
    margin: 32px 0;
    color: #4d4b4b;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    /* letter-spacing: .7px; */
    /* line-height: 1.7rem; */
}


.contact-btn {
    /* width: 18em; */
    padding: 11px;
    color: white;
    border: 4px solid #f1f1f1;;
    outline: transparent;
    background: radial-gradient(#5a80d3, #1422cf);
    font-size: 1.3vw;
    font-family: 'Poppins', sans-serif;
    /* letter-spacing: .8px; */
    cursor: pointer;
    transition: all .4s ease;
    border-radius: 30px;
}

/* Responsive */
@media screen and (max-width: 1030px) {
    .contact-outer {
        margin: 7vw;
    }
    .contact-section {
        display: block;
        /* width: fit-content; */
        height: fit-content;
        margin: 5vw;
    }
    .contact-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 4vw 0;
        text-align: center;
    }
    .contact-image1 {
        width: 100%;
        height: 100%;
    }
    .contact-text-content {
        width: 100%;
        margin-top: 10vw;
        text-align: center;
    }
    .contact-title {
        font-size: 3vw;
    }
    .contact-btn {
        width: fit-content;
        font-size: 1.8vw;
        padding: 6px 11px;
    }
}
