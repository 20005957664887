.ourvalues-outer {
    display: flex;
    justify-content: center;
    border: 2px solid rgb(224, 224, 224);
    margin: 50px 60px;
    border-radius: 20px;
    border-top: #494949;
}
.ourvalues-section {
    width: 80%;
    padding: 32px;
    text-align: center;
    background-color: white;
}
.ourvalues-title-content {
    margin: 0 0 64px 0;
}
.ourvalues-title-contentheading {
    display: flex;
    justify-content: center;
}
.ourvalues-title-content h1{
    width: fit-content;
    font-size: 1.12vw;
    color: #8348f0;
    margin-bottom: 40px;
    background-color: rgb(246, 246, 252);
    border-radius: 24px;
    padding: 5px 13px;
}
.ourvalues-title {
    font-family: 'Poppins', sans-serif;
    font-size: 2vw;
    font-weight: bold;
    /* letter-spacing: .7px; */
}


.ourvalues-description {
    margin: 34px auto 0;
    text-align: center;
    color: rgb(118 116 116);
    font-family: 'Poppins', sans-serif;
    font-size: 1.22vw;
    /* letter-spacing: .8px; */
    /* line-height: 1.8rem; */
}
/* OurValue Cards */
.ourvalues-cards-content {
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.ov-vertical {
    border-right: 2px solid #cfc9c9;
    height: 85px;
}
.ourvalues-card {
    /* width: 300px; */
    /* height: 200px; */
    /* text-align: left; */
    font-family: 'Poppins', sans-serif;
    margin: 20px;
    margin-bottom: 40px;
}
.ourvalues-card-img {
    height: 45px;
    padding: 4px;
    background-position: center;
    background-color: white;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    object-fit: contain;
}
.ourvalues-card-name {
    margin: 12px 0 0 0;
    font-size: 1.1vw;
    font-weight: bold;
    /* letter-spacing: .7px; */
    display: flex;
    justify-content: center;
}
.ourvalues-card-title {
    /* width: 271px; */
    margin: 6px auto;
    margin-top: 18px;
    color: rgb(118 116 116);
    font-family: 'Poppins', sans-serif;
    font-size: 1.04vw;
    /* letter-spacing: .7px; */
    display: flex;
    justify-content: center;
    text-align: center;
}

/* Responsive */
@media screen and (max-width: 1030px) {
    .ourvalues-title-content{
        margin: 2vw 0;
    }
    .ourvalues-outer {
        margin: 7vw 2px;
        border: 0px;
    }
    .ourvalues-title {
        font-size: 4.4vw;
    }
    .ourvalues-description {
        margin: 4vw 0 0;
        font-size: 1.9vw;
        /* line-height: 0.7rem; */
        color: #242323;
    }
    .ov-vertical {
        height: 0;
    }
    .ourvalues-title-content h1 {
        font-size: 2vw;
        margin-bottom: 1.3rem;
        padding: 3px 7px;
    }
    .ourvalues-cards-content {
        flex-wrap: wrap;
        padding: 0 2rem;
    }
    .ourvalues-card{
        margin: 1vw;
    }
    .ourvalues-card-img{
        height: 1.9em;
    }
    .ourvalues-card-title{
        margin-top: 2vw;
        font-size: 2.1vw;
        color: #242323;
    }
    .ourvalues-card-name{
        font-size: 2.3vw;
        margin: 0;
    }
    .ourvalues-section{
        /* padding: 1.2rem; */
        width: fit-content;
        padding: 5px 0;
    }
}
