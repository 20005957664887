.section-container {
    width: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    height: 31em;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 17em 0px;
    padding-bottom: 25em;
    /* border: 2px solid black; */
    /* border-radius: 25px; */
    /* background-color: rgba(48,12,49,0.5); */
}
.hero-section {
    width: 100%;
    /* padding: 32px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 30px;
    
}
#bg-video{
    filter: brightness(0.5) hue-rotate(-315deg) saturate(2);
    opacity: 0.88;
    width: 90%;
    height: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: -1;
    object-fit: cover;
    border-radius: 24px;
    pointer-events: none;
    user-select: none;
}
/* #bg-video::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(48, 12, 49, 0.5);
    z-index: -1;
    pointer-events: none;
} */
/* Text section */
.text-section {
    padding: 0 32px;
    text-align: center;
    margin-top: 24em;
}
.text-headline {
    /* margin-bottom: -34px; */
    margin-top: -2em;
    /* margin-left: 5px; */
    color: rgb(77, 75, 75);
    font-family: 'Poppins', sans-serif;
    font-size: 1.13vw;
    font-weight: bold;
    /* letter-spacing: .7px; */
    background-color: white;
    border-radius: 42px;
    padding: 1.5em 1.5em;
    position: absolute;
    justify-content: center;
    height: 3.5em;
}

.text-title {
    /* width: 49%; */
    margin: 22px auto;
    color: rgb(255, 255, 255);
    font-family: 'Poppins', 'sans-serif';
    font-size: 3vw;
    font-weight: bolder;
    /* letter-spacing: 0.8px; */
}
.text-descritpion {
    /* width: 61%; */
    margin: 22px auto;
    color: rgb(255, 255, 255);
    font-family: 'Poppins';
    font-size: 1.4vw;
    /* letter-spacing: .8px; */
    /* line-height: 1.6rem; */
    font-weight: bolder;
}

.hero-btn {
    width: 11em;
    padding: 11px;
    color: white;
    border: 4px solid white;
    outline: transparent;
    background-color: #8348f0;;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    /* letter-spacing: .8px; */
    cursor: pointer;
    transition: all .4s ease;
    border-radius: 30px;
}

/* Scroll Btn */
.scroll-up {
    width: 45px;
    height: 45px;
    display: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    border: 3px solid white;
    border-radius: 50%;
    background-color: #1A8EFD;
    position: fixed;
    bottom: 50px;
    right: 50px;
    font-size: 24px;
    z-index: 20;
    cursor: pointer;
}  
.show-scroll {
  display: flex;
}
/* Responsive */
@media screen and (max-width: 1030px) {
    .section-container{
        height: 21em;
        width: 96%;
        margin: 13em 0;
    }
    .text-section {
        padding: 0;
        width: 100%;
    }
    .text-headline {
        font-size: 2.1vw;
    }
    .text-title {
        font-size: 4.2vw;
    }
    .text-descritpion {
        font-size: 1.8vw;
        /* line-height: 0.7rem; */
    }
    .scroll-up {
        bottom: 32px;
        right: 32px;
    }
    .hero-btn{
        width: 9em;
        padding: 0.4rem;
        font-size: 2.3vw;    
    }
}
@media screen and (max-width: 550px){
    #bg-video{
        height: 71%;
    }
    .text-section{
        margin-top: 17em;
    }
}
