.ourclient-outer {
    display: flex;
    justify-content: center;
    /* margin-top: 78px; */
}
.barsimg1{
    height: 15%;
    
}
.barsimg2 {
    height: 15%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.ouclient-section {
    /* width: 90%; */
    padding: 32px 0px;
    padding-top: 20px;
    text-align: center;
    background-color: white;

}
.ourclient-title-content {
    margin: 0 0 64px 0;
}
.ourclient-title-contentheading {
    display: flex;
    justify-content: center;
}
.ourclient-title-content h1 {
    width: fit-content;
    color: #8348f0;
    font-size: 1.12vw;
    background-color: rgb(246, 246, 252);
    border-radius: 24px;
    padding: 5px 13px;
    margin-bottom: 15px;
}
.ourclient-title {
    margin-top: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 1.7vw;
    font-weight: bold;
    /* letter-spacing: 0.1ch; */
}
.ourclient-title > span {
    position: relative;
}

.ourclient-description {
    margin: 24px 0px;
    margin-bottom: 0px;
    text-align: center;
    color: #9d9797;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    /* letter-spacing: .8px; */
    /* line-height: 1.8rem; */
}
/* OurClient Cards */
.ourclient-cards-content {
    padding: 0 35px;
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    /* gap: 8px; */
}
.ourclient-card {
    display: flex;
    width: 100%;
    height: 6em;
    gap: 10px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
}
.ourclient-card-img {
    height: 35px;
    padding: 3px;
    background-position: center;
    background-color: white;
}
.ourclient-card-name {
    margin: 2px 0 0 0;
    font-size: 1.02vw;
    font-weight: bold;
    /* letter-spacing: .7px; */
}

/* Responsive */
@media screen and (max-width: 1030px) {
    .ourclient-title-content{
        margin: 0 0 20px 0;
    }
    .ourclient-cards-content{
        flex-wrap: wrap;
        gap: 2vw;
        height: fit-content;
    }
    .ourclient-title {
        font-size: 4vw;
        margin-top: 0;
    }
    .ourclient-description {
        margin-top: 0.7rem;
        font-size: 2.1vw;
        /* line-height: 0.7rem; */
        color: #242323;
    }
    .barsimg1{
        display: none;
    }
    .barsimg2 {
        display: none;
    }
    .ourclient-title-content h1{
        font-size: 2vw;
        padding: 3px 7px;
    }
    .ourclient-card {
        height: fit-content;
    }
    .ourclient-card-img{
        height: 1.5em;
    }
    .ourclient-card-name{
        font-size: 1.6vw;
        margin: auto 0;
    }
}
