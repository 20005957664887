


.ov-section {
    /* width: 90%; */
    padding: 32px 0;
    padding-top: 20px;
    text-align: center;
    background-color: white;
    margin: 10px 0;
    margin-top: 0;

}
.ov-title-content {
    padding: 80px;
    margin: 0 0 52px 0;
    background: #8348f005;
}
.ov-title-contentheading {
    display: flex;
    justify-content: center;
}
.ov-title-content h1 {
    font-weight: bolder;
    color: #8348f0;
    font-size: 3.2vw;
    /* background-color: rgb(246, 246, 252); */
    padding: 3px;
    margin-bottom: 15px;
}
.ov-leadership-title-content h1 {
    font-weight: bolder;
    color: #8348f0;
    font-size: 3.2vw;
    /* background-color: rgb(246, 246, 252); */
    padding: 3px;
    margin-bottom: 15px;
}
.ov-title {
    margin-top: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-weight: bold;
    /* letter-spacing: 0.1ch; */
}


.ov-description {
    margin: 0 54px 0px;
    margin-bottom: 0px;
    text-align: center;
    color: rgb(77, 75, 75);
    font-family: 'Poppins', sans-serif;
    font-size: 1.7vw;
    /* letter-spacing: .8px; */
    /* line-height: 2.4rem; */
}
.ov-leadership-description {
    margin: 24px 54px 0px;
    margin-bottom: 0px;
    text-align: left;
    color: rgb(77, 75, 75);
    font-family: 'Poppins', sans-serif;
    font-size: 1.4vw;
    /* letter-spacing: .8px; */
    /* line-height: 2.4rem; */
}
.ov-leadership-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
}
.ov-leadership-item{
    width: 48%;
    border-radius: 14px;
    margin: 50px 10px;
    padding: 35px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.5);
}

.ov-leadership-item-head{
    margin: 15px 0;
}

.ov-leadership-img{
    background: #8348f011;
    border-radius: 8px;
    height: 1.6em;
}

/* Founder-section */
.founder-section {
    padding: 40px;
    border-radius: 24px;
    border: 4px solid #8348f0;
    display: flex;
    align-items: center;
    gap: 7em;
    justify-content: center;
    margin: 80px 30px;
}

.founder-img {
    height: 16.5em;
    border-radius: 115px;
}
.founder-description {
    font-size: 2vw;
    font-family: 'Poppins', sans-serif;
    color: rgb(77, 75, 75);
    /* letter-spacing: .8px; */
    /* line-height: 2.4rem; */
}
.founder-description h1 {
    text-align: left;
    font-weight: bolder;
    color: #8348f0;
    font-size: 2.3vw;
    /* background-color: rgb(246, 246, 252); */
    padding: 3px;
    margin-bottom: 15px;
}
.founder-list {
    text-align: left;
    font-size: 1.6vw;
}
.founder-list-item{
    margin: 5px 0;
    list-style-type: none;
}


/* Responsive */
@media screen and (max-width: 1030px) {
    .ov-title {
        font-size: 32px;
    }
    .ov-description {
        margin: 0;
        font-size: 3vw;
        text-align: justify;
    }
    .founder-section {
        display: block;
        margin: 7vw 2vw;
        padding: 5vw;
    }
    .founder-description {
        font-size: 3vw;
        /* letter-spacing: 0; */
    }
    .ov-leadership-description {
        font-size: 3vw;
        margin: 0;
        text-align: justify;
    }
    .founder-img {
        height: 10em;
        margin-bottom: 22px;
    }
    .ov-title-content h1{
        font-size: 5vw;
    }
    .ov-section{
        padding: 0;
    }
    .ov-title-content{
        padding: 4vw;
        margin: 5vw 0;
    }
    .ov-leadership-title-content h1{
        font-size: 5vw;
    }
    .ov-leadership-item{
        margin: 5vw 3vw;
        width: 100%;
    }
    .founder-description h1{
        font-size: 3.3vw;
    }
    .founder-list{
        font-size: 2.5vw;
    }
}
