.uppermid-section {
    display: flex;
    justify-content: center;
    margin-top: 30.6em;
    /* padding: 0; */
    /* height: 12em; */
}
.uppermid-title-content {
    border: 2px solid transparent;
    border-radius: 25px;
    /* font-size: 10px; */
    width: fit-content;
    text-align: center;
    z-index: 1;
    background-color: white;
    padding: 35px 0;
    padding-top: 55px;
}
.uppermid-title {
    font-size: 1.9vw;
    font-weight: bold;
    color: black;
    font-family: 'Poppins', sans-serif;
}



/* Responsive */
@media screen and (max-width: 1030px) {
    .uppermid-section {
        margin-top: 24.6em;
    }
    .uppermid-title{
        font-size: 1.9vw;
    }
    .uppermid-title-content{
        padding: 1.5vw 3px;
    }
    
}
@media screen and (max-width: 550px){
    .uppermid-section{
        margin-top: 17.8em;
    }
}