.aboutdark-outer {
    margin: 0 29px;
    /* padding: 7em; */
    /* padding-bottom: 12.6em; */
    display: flex;
    justify-content: center;
}
.aboutdark-section {
    width: 95%;
    padding: 18px;
    display: flex;
    /* height: 20em; */
    /* justify-content: center; */
    gap: 24px;
    background: url(../Assets/whoarewebackground.png);
    /* filter: brightness(0.6) hue-rotate(-305deg) saturate(2); */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 25px;
    /* margin: 80px 0px; */
    margin-top: 30px;
}
/* Image */

.aboutdark-image1 {
    width: auto;
    height: 16em;
}
/* Text */
/* .aboutdark-text-content {
    width: 90%;
} */
.aboutdark-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6vw;
    font-weight: bold;
    color: #ffffff;
    margin: 15px;
    margin-bottom: 25px;
    /* letter-spacing: 0.9px; */
    margin-left: 0;
}

.aboutdark-description {
    margin: 20px 0;
    /* width: 85%; */
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1vw;
    /* letter-spacing: .9px; */
    /* line-height: 1.7rem; */
    font-weight: bold;
}

/* Responsive */
@media screen and (max-width: 1030px) {
    .aboutdark-outer{
        margin: 0 6vw;
        /* height: 26em; */
    }
    .aboutdark-section {
        display: block;
        height: fit-content;
        margin-top: 4vw;
    }
    .aboutdark-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 4vw 0;
        text-align: center;
    }
    .aboutdark-image1 {
        /* width: 70%; */
        height: 36vw;
    }
    .aboutdark-text-content {
        width: 100%;
        /* margin: 0 20vw; */
    }
    .aboutdark-description {
        font-size: 1.8vw;
        /* line-height: 0.7rem; */
    }
    .aboutdark-title{
        font-size: 2.5vw;
    }
}
