

.office-section {
    /* width: 90%; */
    padding: 32px;
    padding-top: 20px;
    text-align: center;
    background-color: white;

}
.office-title-content {
    margin: 34px auto;
}

.office-title-content h1 {
    color: #8348f0;
    font-size: 2.8vw;
    /* background-color: rgb(246, 246, 252); */
    padding: 3px;
    font-family: 'Poppins', 'sans-serif';
}
.office-title-content h3 {
    color: black;
    font-size: 1.5vw;
    font-weight: normal;
    padding: 3px;
    margin-bottom: 15px;
    font-family: 'Poppins', 'sans-serif';
}
.office-title {
    margin-top: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-weight: bold;
    /* letter-spacing: 0.1ch; */
}
.office-address-outer {
    display: flex;
}


.office-address-section {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.5);
    padding: 20px 29px;
    align-items: center;
    gap: 1em;
    justify-content: center;
    margin: 30px 60px;
    width: 50%;
    border-radius: 14px;

}
.office-img-section{
    text-align: left;
}
.office-img {
    width: 100%;
    height: 20em;
    border-radius: 10px;
}
.office-description {
    /* font-size: 1.3vw; */
    font-family: 'Poppins', sans-serif;
    color: rgb(77, 75, 75);
    /* letter-spacing: .8px; */
    /* line-height: 2.4rem; */
    text-align: left;
}

.office-description-title{
    font-size: 1.2vw;
    margin: 8px 0;
    margin-top: 15px;
    color: #8348f0;
}

.office-description-content{
    font-size: 1.2vw;
}

/* Responsive */
@media screen and (max-width: 1030px) {
    
    .office-description {
        margin: 5vw 0 0;
        font-size: 3vw;
    }
    .office-address-outer {
        display: block;
    }
    .office-address-section {
        display: block;
        width: 100%;
        margin: 8vw 1vw;
        padding: 5vw;
    }
    .office-title-content {
        margin: 0;
    }
    .office-title-content h1 {
        font-size: 4vw;
    }
    .office-section{
        padding: 2vw;
    }
    .office-description-content{
        font-size: 2.2vw;
    }
    .office-description-title{
        font-size: 2.2vw;
    }
    .office-title-content h3{
        font-size: 2.5vw;
    }
}
