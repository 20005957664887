
.os-section {
    /* width: 80%; */
    /* padding: 32px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background: white;
    margin: 165px 34px;
    margin-top: 140px;
}

 .side-img-service {
    border: 2px solid white;
    border-radius: 12px;
    height: 23em;
    object-fit: cover;
    /* background-image: linear-gradient(80deg, #cbe9f5, #ebe0e0ab); */
 }
.os-head {
    width: fit-content;
    border: 2px solid #8348f0;
    margin: 8px 0px;
    margin-bottom: 25px;
    color: #8348f0;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1vw;
    /* letter-spacing: .7px; */
    /* line-height: 1.7rem; */
    padding: 1px 17px;
    border-radius: 24px;
    font-weight: bold;
}
/* Image */
.os-image-content {
    /* width: 50%; */
    /* padding-left: 50px; */
    text-align: center;
}
.os-image1 {
    width: 80%;
    height: auto;
}
/* Text */
.os-text-content {
    width: 50%;
    margin-left: 35px;
}
.os-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.8vw;
    font-weight: bold;
    width: 14em;
    margin: 26px 0;
}

.os-description {
    margin: 8px 0px;
    margin-bottom: 18px;
    color: #9d9797;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    /* letter-spacing: .7px; */
    /* line-height: 1.7rem; */
}

.text-appointment-btn {
    padding: 10px 20px;
    color: white;
    border: 4px solid white;
    border-radius: 28px;
    outline: transparent;
    background: radial-gradient(#AA7CF3, #813FEA);
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    /* letter-spacing: .8px; */
    cursor: pointer;
    transition: all .4s ease;
    border-radius: 30px;
}
/* .fa-icon {
    color: #9D2553;
} */
/* Responsive */
@media screen and (max-width: 1030px) {
    .os-section {
        display: block;
        margin: 7vw;
    }
    #aiconsulting{
        display: flex;
        flex-wrap: wrap-reverse;
    }
    #differentiateai{
        display: flex;
        flex-wrap: wrap-reverse;
    }
    #engineerdata{
        display: flex;
        flex-wrap: wrap;
    }
    #operationalize{
        display: flex;
        flex-wrap: wrap-reverse;
    }
    #visualisation{
        display: flex;
        flex-wrap: wrap;
    }
    .os-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }
    .os-text-content {
        width: 100%;
        margin: 10px 5px;
    }
    .side-img-service {
        height: 39vw;
    }
    .os-head {
        font-size: 2.5vw;
    }
    .os-description{
        font-size: 2.6vw;
        color: rgb(77, 75, 75);
    }
    .os-title {
        font-size: 5vw;
    }
    .text-appointment-btn{
        font-size: 2.3vw;
        padding: 1vw 1.5vw;
    }
}
