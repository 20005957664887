.about2-outer {
    display: flex;
    justify-content: center;
}
.about2-section {
    width: 80%;
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: white;
    padding-left: 78px;
}
/* Image */
.about2-image-content {
    width: 50%;
    max-width: 100%;
    text-align: center;
}
.about2-image1 {
    width: 80%;
    height: auto;
}
#Ai-video {
    height: 27vw;
    filter: brightness(1.1) hue-rotate(-897deg) saturate(1);
    pointer-events: none;
}
/* Text */

.about2-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.8vw;
    font-weight: bold;
}

.about2-description {
    margin:16px 0;
    color: #9d9797;
    font-family: 'Poppins', sans-serif;
    font-size: 1.08vw;
    /* letter-spacing: .9px; */
    /* line-height: 30px; */
    margin-bottom: 8px;
}

/* .text-appointment-btn {
    padding: 10px 20px;
    color: white;
    border: 4px solid white;
    border-radius: 28px;
    outline: transparent;
    background: radial-gradient(#baa0e2, #813FEA);
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
    border-radius: 30px;
} */

/* Responsive */
@media screen and (max-width: 1030px) {
    .about2-section {
        display: flex;
        padding: 25px 0;
        flex-wrap: wrap-reverse;
    }
    .about2-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }
    .about2-image1 {
        width: 100%;
        height: 100%;
    }
    .about2-text-content {
        width: 100%;
        margin: 18px 0;
    }
    #Ai-video{
        height: 55vw;
    }
    .about2-title {
        font-size: 5vw;
    }
    .about2-description {
        font-size: 2.3vw;
        margin: 12px 0;
        /* line-height: 0.8rem; */
    }
}
