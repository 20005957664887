.footer-section {
    background-color: #1d1f22;
    border-top: 2px solid transparent;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.footer-top {
    display: flex;
    border-bottom: 2px solid #2f2d2d;
    padding: 20px;
}
.footer-top-content {
    width: 100%;
    margin-left: 3em;
    color: white;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center;
}
.wp-link-sc {
    text-decoration: none;
}
.footer-top-btn-sc {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-right: 5em;
    align-items: center;
}

.footer-top-btn-sc button {
    border: 2px solid transparent;
    position: fixed;
    top: 90%;
    left: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 3.5em;
    font-size: 17px;
    border-radius: 30px;
    background-color: rgb(181, 247, 181);
    cursor: pointer;
}
.wp-logo-sc {
    height: 45px;
}

.wp-link {
    text-decoration: none;
}
.footer-top-btn {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-right: 5em;
    align-items: center;
}

.footer-top-btn button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    width: 9em;
    font-size: 17px;
    border-radius: 30px;
    background-color: rgb(181, 247, 181);
    font-weight: 300;
    cursor: pointer;
}
.wp-logo {
    padding-right: 8px;
    height: 25px;
}
.footer-container {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    color: white;
    border-bottom: 2px solid #293241;
    border-radius: 2px;
}
/* Title */
.ft-title {
    color: #1A8EFD;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: .8px;
}
.ft-sign {
    color: #1ECAB0;
    font-family: Cambria, sans-serif;
    font-size: 40px;
    font-weight: bold;
}
.ft-description {
    width: 40em;
    margin: 16px 0 40px;
    color: #D0D3DA;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    /* letter-spacing: .8px; */
    /* line-height: 1.7rem; */
}
.ft-input-title {
    color: white;
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    font-weight: bold;
    /* letter-spacing: .8px; */
}


/* Lists */
.ft-list-outer {
    display: flex;
    gap: 3vw;
}
.ft-list-title {
    margin: 16px 0;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: bold;
    /* letter-spacing: .8px; */
    color: rgb(196, 109, 82);
}
.ft-list-items {
    list-style-type: none;
}
.ft-list-items li a {
    text-decoration: none;
    color: #a5a7ac;
    padding: 0 0 2px 0;
    border-bottom: 2px dotted transparent;
    transition: border .2s ease;
}
.ft-list-items li {
    margin: 10px 0;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: bold;
    /* letter-spacing: .8px; */
}
.ft-list-items li a:hover {
    border-bottom: 2px dotted #a5a7ac;
}
/* CopyRight */
.ft-copyright {
    padding: 24px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #cbcdd3;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: bold;
    /* letter-spacing: .8px; */
}
.ft-social-links {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.ft-social-links li a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Responsive */
@media screen and (max-width: 1030px) {
    .footer-top-btn-sc button{
        left: 5%;
    }
    .footer-container {
        padding: 24px;
    }
    .ft-description {
        width: 100%;
        margin: 16px 0 24px;
        font-size: 2vw;
    }

    .ft-btn {
        width: 100%;
        margin: 0 0 16px;
    }
    .ft-copyright {
        padding: 18px;
        display: block;
        text-align: center;
    }
    .ft-social-links {
        margin: 4vw 0 0;
    }
    .ft-social-links li{
        font-size: 4vw;
    }
    .ft-list-items li {
        font-size: 2vw;
    }
    .ft-list-title {
        font-size: 2vw;
    }
    .ft-list-outer {
        /* gap: 10vw; */
        flex-wrap: wrap;
    }
    .footer-top-content {
        justify-content: center;
        margin: 2vw 0;
        font-size: 3vw;
    }
    .footer-top-btn button {
        font-size: 2.5vw;
    }
    .footer-top{
        display: block;
        padding: 3vw;
    }
    .footer-top-btn{
        justify-content: center;
    }
    .ft-title img {
        height: 8vw;
    }
    .ft-copyright p{
        font-size: 3vw;
    }
    .wp-logo{
        height: 3vw;
    }
}
